import type { AppProps } from 'next/app'
import { Archivo_Black, Lora, Mulish } from 'next/font/google'
import localFont from 'next/font/local'
import { ToastContainer } from 'react-toastify'
import { useMedia } from 'react-use'
import { SharedLoadingIndicatorContextProvider, SharedProgressLoadingIndicator } from 'shared-loading-indicator'
import { PageNavigationLoadingTracker } from '../components/PageNavigationLoadingTracker'
import '../styles/globals.sass'
import { api } from '../utilities/api'
import { registerServiceWorker } from '../utilities/registerServiceWorker'

const lora = Lora({
	subsets: ['latin', 'latin-ext'],
})

const mulish = Mulish({
	subsets: ['latin', 'latin-ext'],
	weight: '600',
})

const archivoBlack = Archivo_Black({
	subsets: ['latin', 'latin-ext'],
	weight: '400',
})

const switzer = localFont({
	src: [
		{
			path: '../../public/fonts/switzer-regular-webfont.woff2',
			weight: '400',
		},
	],
})

registerServiceWorker()

function MyApp({ Component, pageProps }: AppProps) {
	const theme = useMedia('(prefers-color-scheme: dark)', false) ? 'dark' : 'light'

	return (
		<>
			<style jsx global>{`
				html {
					--font-Lora: ${lora.style.fontFamily};
					--font-ArchivoBlack: ${archivoBlack.style.fontFamily};
					--font-Switzer: ${switzer.style.fontFamily};
					--font-Mulish: ${mulish.style.fontFamily};
				}
			`}</style>
			<SharedLoadingIndicatorContextProvider>
				<PageNavigationLoadingTracker />
				<SharedProgressLoadingIndicator />
				<ToastContainer theme={theme} />
				<Component {...pageProps} />
			</SharedLoadingIndicatorContextProvider>
		</>
	)
}

export default api.withTRPC(MyApp)
